footer {
  margin-top: 80px; }

.footer.footer-default {
  background-color: #333;
  color: #fff; }

.footer.footer-default a {
  color: #fff; }

.footer.footer-default a:hover {
  color: #e6e6e6; }

.qualifications {
  padding-bottom: 40px; }
  .qualifications a {
    opacity: 1;
    transition: opacity 0.4s ease-in-out; }
    .qualifications a:hover {
      opacity: 0.5; }
    .qualifications a img {
      max-height: 100px;
      padding-right: 20px; }

.call-to-action {
  background-color: #333;
  padding: 40px 0; }

.call-to-action p {
  color: #fff;
  font-size: 20px; }

.logo-image {
  max-width: 150px;
  padding-top: 40px; }

/* change this */
.info p, p, li {
  font-weight: 400;
  font-size: 16.8px; }

.footer li {
  font-size: 14px; }

.description, .card-description, .footer-big p {
  font-weight: 400; }

.navbar.navbar-transparent {
  padding-top: 0 !important; }

.non-header-page .navbar {
  background-color: #333; }

.non-header-page .navbar.navbar-transparent {
  background-color: #333 !important; }

.non-header-page .wrapper {
  padding-top: 180px; }

.section {
  padding: 0; }

.validation-summary-errors li {
  color: red; }

.rich-text {
  padding-top: 40px;
  padding-bottom: 40px; }

.carousel {
  width: 100%; }
  .carousel img {
    width: 100%; }

.child-pages {
  padding-top: 40px;
  padding-bottom: 40px; }

.child-pages-card-title a {
  font-size: 1.825em;
  margin-bottom: 30px;
  line-height: 1.4em;
  color: #fff !important; }
  .child-pages-card-title a:hover {
    color: #cccccc !important; }

.content-block-blocks .content-block {
  background-size: cover;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .content-block-blocks .content-block p {
    color: #fff;
    font-size: 24px; }

/* style them to be like the Now UI cards */
.content-block-cards .content-block {
  background-size: cover;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .content-block-cards .content-block p {
    color: #fff;
    font-size: 24px; }
